<template>
<!-- eslint-disable -->
	<div>
		<h2 class="text-center" style="font-size: 28px!important; margin: 70px 0;">takenoBox</h2>

		<v-menu
			v-model="menu"
			origin="center center"
			transition="scale-transition"
			offset-x
			offset-y
			min-width="auto"
			absolute
			nudge-left="265"
			nudge-bottom="30"
		>
			<template v-slot:activator="{ on, attrs }">
				<v-icon
					v-bind="attrs"
					v-on="on"
					style="position: absolute; top:90px; right: 100px;"
				>mdi-help-circle-outline</v-icon>
			</template>
			<v-card style="width: 330px;">
				<v-card-text class="text-center">
						50takenoを使ってBOXを開けられます！<br/>中にはお宝が入っているとかいないとか。。
				</v-card-text>
			</v-card>
		</v-menu>



		<img v-if="!opFlg" src="../../../public/treasureCl.png" :class="animation()" style="max-width: 250px; margin-left: 70px;" alt="treasure">
		<img v-if="opFlg" src="../../../public/treasureOp.png" style="max-width: 250px; margin-left: 70px;" alt="treasure">
		<v-row>
			<v-btn class="mx-auto" style="margin-top: 30px; width: 125px;" dark elevation="5" @click="open()">Boxを開ける</v-btn>
		</v-row>
	</div>
</template>
<script>
/* eslint-disable */
import * as api from '@/api.js'
export default {
	name: 'Gacha',
    data () {
      return {
		// アニメーション
		animationFlg: false,
		opFlg: false,
		// ダイアログ
		popFlg: false,

		menu: false,
      }
    },

	methods: {
		load(){
		},
		open() {
			this.animationFlg = true
			this.$store.dispatch('nowLoading', true)
			api.takeno.gacha().then((res) => {
				switch(res.data.status) {
					case 200:
						this.pop('おめでとう！', res.data.content + 'をGETした!')
						break
					case 500:
						this.pop('エラー', res.data.error[0])
						break
				}
				this.opFlg = true
			}).finally(this.$store.dispatch('nowLoading', false))
		},
		animation() {
			return this.animationFlg ? 'animation' : ''
		},
		pop(title, message) {
			this.$store.dispatch('setDialog', { title: title, message: message });
		},
	},
	created() {
		// this.load()
	},
}
</script>

<style>
.Gacha::after {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color:#fff;  /* 背景カラー */
  z-index: 9999;  /* 一番手前に */
  pointer-events: none;  /* 他の要素にアクセス可能にするためにポインターイベントは無効に */
  opacity: 0;  /* 初期値 : 透過状態 */
  -webkit-transition: opacity .8s ease;  /* アニメーション時間は 0.8秒 */
  transition: opacity .8s ease;
}

.Gacha.white::after {
  opacity: 1;
}

.animation {
    transform-origin: center bottom;
    animation: yurayura 1s linear 2;
}

@keyframes yurayura {
  0% , 100%{
      transform: rotate(5deg);
  }
  50%{
      transform: rotate(-5deg);
  }
}
</style>
